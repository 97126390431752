import { isEmptyNums, isInvalidNums, isNegative, isLessThanTwo, isNotPosWholeNumberSS, isNotPercentageCustom, isNotPosWholeNumberXN, isNotWholeNumberCustom, isNotWholeNumber, isXNegative, isEqualVariances } from '@/js/lib/input-check.js'
import errorText from '@/js/lib/error-handle-text.js'
import normalData from '@/js/distributions/normal-data.js'
import _ from 'lodash'
var jStat = require('jstat').jStat
const chi = require('chi-squared')

const calculateOneMean = (data) => {
    var { selected, μ0, x̄, s, n } = data
    var output = {}
    _.set(output, 'input', { μ0, x̄, s, n })

    selected = selected.value
    var Parsedμ0 = Number(μ0)
    var Parsedx̄ = Number(x̄)
    var Parseds = Number(s)
    var Parsedn = Number(n)

    if (isEmptyNums(μ0, x̄, s, n)) { return }
    if (isInvalidNums(Parsedμ0, Parsedx̄, Parseds, Parsedn)) { return }
    if (isNegative(Parseds)) { return }
    if (isLessThanTwo(Parsedn)) { return }
    if (isNotPosWholeNumberSS(Parsedn)) { return }

    if (n < 30) { // calculate t
        var tStarCalculated = (Parsedx̄ - Parsedμ0) / (Parseds / Math.sqrt(Parsedn))
        var CalculatedDf = Parsedn - 1
        var tStarCalculatedFixed = parseFloat(tStarCalculated.toFixed(2))
        const p = jStat.studentt.cdf(tStarCalculatedFixed, CalculatedDf)
        var CalculatedProbability

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            const tStarABSFixed = parseFloat(Math.abs(tStarCalculated).toFixed(2))
            const p = jStat.studentt.cdf(tStarABSFixed, CalculatedDf)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: tStarCalculatedFixed, // t
            df: CalculatedDf,
        })
    } else { // calculate z
        var zStarCalculated = (Parsedx̄ - Parsedμ0) / (Parseds / Math.sqrt(Parsedn))
        var zStarCalculatedFixed = parseFloat(zStarCalculated.toFixed(2))
        const p = jStat.normal.cdf(zStarCalculatedFixed, 0, 1)

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            const zStarABSFixed = parseFloat(Math.abs(zStarCalculated).toFixed(2))
            const p = jStat.normal.cdf(zStarABSFixed, 0, 1)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: zStarCalculatedFixed,
        })
    }
    var dataNums = _.mapValues(output.data, _.toNumber)
    _.assign(output.data, dataNums)
    return output
}

const calculateOneProportion = (data) => {
    var { selected, p0, x, n } = data
    var output = {}
    _.set(output, 'input', { p0, x, n })

    selected = selected.value
    var Parsedp0 = Number(p0)
    var Parsedx = Number(x)
    var Parsedn = Number(n)

    if (isEmptyNums(p0, x, n)) { return }
    if (isInvalidNums(Parsedp0, Parsedx, Parsedn)) { return }
    if (isNotPercentageCustom(p0)) {
        alert(errorText.hypothesisAlert.pAlert)
        return
    }
    if (isLessThanTwo(Parsedn)) { return }
    if (isXNegative(Parsedx)) { return }
    if (isNotWholeNumberCustom(Parsedn, Parsedx)) {
        alert(errorText.hypothesisAlert.nxAlert)
        return
    }
    if (Parsedx > Parsedn) {
        alert('x must be smaller than n')
    }
    if (!(Parsedx >= 5 && (Parsedn - Parsedx >= 5))) {
        alert('Error. Must have: x ≥ 5 and n-x ≥ 5')
        return
    }

    var pHat = (Parsedx / Parsedn)
    var zStarCalculated = (pHat - Parsedp0) / Math.sqrt(Parsedp0 * (1 - Parsedp0) / Parsedn)
    var zStarCalculatedFixed = parseFloat(zStarCalculated.toFixed(2))
    var p = jStat.normal.cdf(zStarCalculatedFixed, 0, 1)
    var CalculatedProbability

    if (selected === '<') {
        CalculatedProbability = p
    } else if (selected === '>') {
        CalculatedProbability = 1 - p
    } else {
        const zStarABSFixed = parseFloat(Math.abs(zStarCalculated).toFixed(2))
        const p = jStat.normal.cdf(zStarABSFixed, 0, 1)
        CalculatedProbability = (1 - p) * 2
    }
    _.set(output, 'data', {
        prob: CalculatedProbability.toFixed(4),
        z: zStarCalculatedFixed,
    })
    var dataNums = _.mapValues(output.data, _.toNumber)
    _.assign(output.data, dataNums)
    return output
}

const calculateOneVariance = (data) => {
    var { selected, userPopVar, s, n } = data
    var output = {}
    _.set(output, 'input', { userPopVar, s, n })

    selected = selected.value
    const parseduserPopVar = Number(userPopVar)
    const parsedn = Number(n)
    const parseds = Number(s)

    if (isEmptyNums(userPopVar)) { return }
    if (isInvalidNums(parseduserPopVar)) { return }
    if (isNegative(parseduserPopVar)) { return }

    const df = parsedn - 1
    const userSampVar = parseds * parseds

    const chiSq = df * (userSampVar / parseduserPopVar)
    const chiSq2 = chiSq.toFixed(2)

    const probLeft = chi.cdf(chiSq2, df)
    const probRight = 1 - probLeft

    let pVal = 0
    if (selected === '>') {
        pVal = probRight
    } else if (selected === '<') {
        pVal = probLeft
    } else {
        const min = _.minBy([probLeft, probRight])
        pVal = 2 * min
    }
    const pVal4 = pVal.toFixed(4)

    // console.log({ n, s, df, userSampVar, chiSq, chiSq2, probLeft, probRight, pVal, pVal4 })

    _.set(output, 'data', {
        df,
        chiSq2,
        probLeft,
        probRight,
        pVal4
    })
    var dataNums = _.mapValues(output.data, _.toNumber)
    _.assign(output.data, dataNums)
    return output
}

const calculateTwoMean = (data) => {
    var { choiceData, n, dBar, sd, x̄1, x̄2, n1, n2, s1, s2 } = data
    var output = {}
    _.set(output, 'input', { n, dBar, sd, x̄1, x̄2, n1, n2, s1, s2 })
    var selected = choiceData.selected.value
    var computationPicked = choiceData.computationPicked

    var ParsedDbar = parseFloat(dBar)
    var Parsedsd = parseFloat(sd)
    var Parsedn = parseFloat(n)
    var Parsedx̄1 = Number(x̄1)
    var Parsedx̄2 = Number(x̄2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)
    var Parseds1 = Number(s1)
    var Parseds2 = Number(s2)

    if (isCompDependent(data)) {
        if (isEmptyNums(dBar, sd, n)) { return }
        if (isInvalidNums(ParsedDbar, Parsedsd, Parsedn)) { return }
        if (isLessThanTwo(Parsedn)) { return }
        if (isNotPosWholeNumberSS(Parsedn)) { return }
        if (isNegative(Parsedsd)) { return }
    } else {
        if (isEmptyNums(x̄1, x̄2, n1, n2, s1, s2)) { return }
        if (isInvalidNums(Parsedx̄1, Parsedx̄2, Parsedn1, Parsedn2, Parseds1, Parseds2)) { return }
        if (isNegative(Parseds1, Parseds2)) { return }
        if (isNotPosWholeNumberSS(Parsedn1, Parsedn2)) { return }
        if (isCompIndLarge(data)) {
            if (Parsedn1 < 30 || Parsedn2 < 30) {
                alert('Error Sample sizes must be at least 30')
                return
            }
        } else {
            if (isLessThanTwo(Parsedn1)) { return }
            if (isLessThanTwo(Parsedn2)) { return }
            if (Parsedn1 >= 30 && Parsedn2 >= 30) {
                alert('Error One sample size must be < 30')
                return
            }
            if (isCompIndSmallEq(data)) {
                if (!isEqualVariances(Parsedn1, Parsedn2, Parseds1, Parseds2)) { return }
            }
        }
    }
    var {
        TopCalc, CalculatedDf, BottomCalc, zStarCalculated, zStarCalculatedFixed, tStarCalculated,
        tStarCalculatedFixed, CalculatedProbability, CalculatedSp2, p, zStarABSFixed, tStarABSFixed
    } = ''
    if (computationPicked === 'TwoDLarge') {
        zStarCalculated = ParsedDbar / (Parsedsd / Math.sqrt(Parsedn))
        zStarCalculatedFixed = parseFloat(zStarCalculated.toFixed(2))
        p = jStat.normal.cdf(zStarCalculatedFixed, 0, 1)

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            zStarABSFixed = parseFloat(Math.abs(zStarCalculated).toFixed(2))
            p = jStat.normal.cdf(zStarABSFixed, 0, 1)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: zStarCalculatedFixed,
        })
    } else if (computationPicked === 'TwoDSmall') {
        tStarCalculated = ParsedDbar / (Parsedsd / Math.sqrt(Parsedn))
        tStarCalculatedFixed = parseFloat(tStarCalculated.toFixed(2))
        CalculatedDf = Parsedn - 1
        p = jStat.studentt.cdf(tStarCalculatedFixed, CalculatedDf)

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            tStarABSFixed = parseFloat(Math.abs(tStarCalculated).toFixed(2))
            p = jStat.studentt.cdf(tStarABSFixed, CalculatedDf)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: tStarCalculatedFixed, // t
            df: CalculatedDf
        })
    } else if (computationPicked === 'TwoILarge') {
        TopCalc = (Parsedx̄1 - Parsedx̄2)
        BottomCalc = Math.sqrt(Math.pow(Parseds1, 2) / Parsedn1 + Math.pow(Parseds2, 2) / Parsedn2)
        zStarCalculated = (TopCalc / BottomCalc)
        zStarCalculatedFixed = parseFloat(zStarCalculated.toFixed(2))
        p = jStat.normal.cdf(zStarCalculatedFixed, 0, 1)

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            zStarABSFixed = parseFloat(Math.abs(zStarCalculated).toFixed(2))
            p = jStat.normal.cdf(zStarABSFixed, 0, 1)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: zStarCalculatedFixed,
        })
    } else if (computationPicked === 'TwoISmallEqual') {
        TopCalc = (Parsedx̄1 - Parsedx̄2)
        CalculatedDf = Parsedn1 + Parsedn2 - 2
        CalculatedSp2 = ((Parsedn1 - 1) * Math.pow(Parseds1, 2) + (Parsedn2 - 1) * Math.pow(Parseds2, 2)) / CalculatedDf
        BottomCalc = Math.sqrt(CalculatedSp2 * ((1 / Parsedn1) + (1 / Parsedn2)))
        tStarCalculated = (TopCalc / BottomCalc)
        tStarCalculatedFixed = parseFloat(tStarCalculated.toFixed(2))
        p = jStat.studentt.cdf(tStarCalculatedFixed, CalculatedDf)

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            tStarABSFixed = parseFloat(Math.abs(tStarCalculated).toFixed(2))
            p = jStat.studentt.cdf(tStarABSFixed, CalculatedDf)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: tStarCalculatedFixed, // t
            df: CalculatedDf,
        })
    } else if (computationPicked === 'TwoISmallNotEqual') {
        var df = 0
        if (Parsedn1 === Parsedn2) {
            df = Parsedn1 + Parsedn2 - 2
        } else {
            const top = Math.pow((Math.pow(Parseds1, 2) / Parsedn1) + (Math.pow(Parseds2, 2) / Parsedn2), 2)
            const tempBot1 = Math.pow(Parseds1, 2) / Parsedn1
            const bot1 = Math.pow(tempBot1, 2) / (Parsedn1 - 1)
            const tempBot2 = Math.pow(Parseds2, 2) / Parsedn2
            const bot2 = Math.pow(tempBot2, 2) / (Parsedn2 - 1)
            df = top / (bot1 + bot2)
        }
        CalculatedDf = Math.round(df)
        TopCalc = (Parsedx̄1 - Parsedx̄2)
        BottomCalc = Math.sqrt(Math.pow(Parseds1, 2) / Parsedn1 + Math.pow(Parseds2, 2) / Parsedn2)
        tStarCalculated = (TopCalc / BottomCalc)
        tStarCalculatedFixed = parseFloat(tStarCalculated.toFixed(2))
        p = jStat.studentt.cdf(tStarCalculatedFixed, CalculatedDf)

        if (selected === '<') {
            CalculatedProbability = p
        } else if (selected === '>') {
            CalculatedProbability = 1 - p
        } else {
            tStarABSFixed = parseFloat(Math.abs(tStarCalculated).toFixed(2))
            p = jStat.studentt.cdf(tStarABSFixed, CalculatedDf)
            CalculatedProbability = (1 - p) * 2
        }
        _.set(output, 'data', {
            prob: CalculatedProbability.toFixed(4),
            z: tStarCalculatedFixed, // t
            df: CalculatedDf,
        })
    }
    const dataNums = _.mapValues(output.data, _.toNumber)
    _.assign(output.data, dataNums)
    return output
}

const calculateTwoProportion = (data) => {
    var { choiceData, x̄1, x̄2, n1, n2 } = data
    var output = {}
    _.set(output, 'input', { x̄1, x̄2, n1, n2 })
    var selected = choiceData.selected.value

    var Parsedx1 = Number(x̄1)
    var Parsedx2 = Number(x̄2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)

    if (isEmptyNums(x̄1, x̄2, n1, n2)) { return }
    if (isInvalidNums(Parsedn1, Parsedn2, Parsedx1, Parsedx2)) { return }
    if (isNotPosWholeNumberXN(Parsedn1, Parsedn2, Parsedx1, Parsedx2)) { return }
    if (!((Parsedx1 >= 5) && (Parsedn1 - Parsedx1 >= 5) && (Parsedx2 >= 5) && (Parsedn2 - Parsedx2 >= 5))) {
        alert('Error. Must have: x₁ ≥ 5 and n₁ - x₁ ≥ 5 and x₂ ≥ 5 and n₂ - x₂ > 5 and n₂ - x₂ > 5')
        return
    }

    var {
        pHat, qHat, p1Hat, p2Hat, top, bottom, zStarCalculated,
        zStarCalculatedFixed, zStarABSFixed, p, CalculatedProbability, zNeg
    } = ''
    pHat = (Parsedx1 + Parsedx2) / (Parsedn1 + Parsedn2)
    qHat = 1 - pHat
    p1Hat = (Parsedx1 / Parsedn1)
    p2Hat = (Parsedx2 / Parsedn2)
    top = (p1Hat - p2Hat)
    bottom = Math.sqrt(pHat * qHat * (1 / Parsedn1 + 1 / Parsedn2))
    zStarCalculated = (top / bottom)
    zStarCalculatedFixed = parseFloat(zStarCalculated.toFixed(2))
    p = jStat.normal.cdf(zStarCalculatedFixed, 0, 1)

    if (selected === '<') {
        CalculatedProbability = p
    } else if (selected === '>') {
        CalculatedProbability = 1 - p
    } else {
        zStarABSFixed = parseFloat(Math.abs(zStarCalculated).toFixed(2)) * -1
        zNeg = -1 * zStarABSFixed
        p = jStat.normal.cdf(zNeg, 0, 1)
        CalculatedProbability = (1 - p) * 2
    }
    _.set(output, 'data', {
        prob: CalculatedProbability.toFixed(4),
        z: zStarCalculatedFixed,
    })

    const dataNums = _.mapValues(output.data, _.toNumber)
    _.assign(output.data, dataNums)
    return output
}

const calculateTwoVariance = (data) => {
    var { selected, n1, n2, s1, s2 } = data
    var output = {}
    _.set(output, 'input', { n1, n2, s1, s2 })

    selected = selected.value
    const parsedn1 = Number(n1)
    const parsedn2 = Number(n2)
    const parseds1 = Number(s1)
    const parseds2 = Number(s2)

    if (isEmptyNums(s1, s2, n1, n2)) { return }
    if (isInvalidNums(parsedn1, parsedn2, parseds1, parseds2)) { return }
    if (isNegative(parseds1, parseds2)) { return }
    if (isLessThanTwo(parsedn1, parsedn2)) { return }
    if (isNotWholeNumber(parsedn1, parsedn2)) { return }

    const df1 = n1 - 1
    const df2 = n2 - 1
    let { right, pVal, pVal4, upperF, upperF2, lowerF, lowerF2, probRight, probLeft } = ''

    const Fval = (s1 * s1) / (s2 * s2)
    const Fval2 = Fval.toFixed(2) // Fval rounded to 2 decimal places

    if (selected === '<') {
        pVal = jStat.centralF.cdf(Fval2, df1, df2)
        pVal4 = pVal.toFixed(4)
    } else if (selected === '>') {
        pVal = 1 - jStat.centralF.cdf(Fval2, df1, df2)
        pVal4 = pVal.toFixed(4)
    } else {
        if (parseds1 >= parseds2) {
            right = true

            upperF = (s1 * s1) / (s2 * s2)
            upperF2 = upperF.toFixed(2)

            lowerF = 1 / upperF
            lowerF2 = lowerF.toFixed(2)

            probRight = 1 - jStat.centralF.cdf(upperF2, df1, df2)
            probLeft = jStat.centralF.cdf(lowerF2, df1, df2)

            pVal = 2 * probRight
            pVal4 = pVal.toFixed(4)
        } else if (parseds1 < parseds2) {
            right = false

            lowerF = (s1 * s1) / (s2 * s2)
            lowerF2 = lowerF.toFixed(2)

            upperF = 1 / lowerF
            upperF2 = upperF.toFixed(2)

            probLeft = jStat.centralF.cdf(lowerF2, df1, df2)
            probRight = 1 - jStat.centralF.cdf(upperF2, df1, df2)

            pVal = 2 * probLeft
            pVal4 = pVal.toFixed(4)
        }
    }

    // console.log(output.input)
    // console.log({ right, df1, df2, Fval2, upperF2, lowerF2, probLeft, probRight, pVal4 })

    _.set(output, 'data', {
        right, df1, df2, Fval2, upperF2, lowerF2, pVal4
    })
    var dataNums = _.mapValues(output.data, _.toNumber)
    _.assign(output.data, dataNums)
    return output
}

const isCompDependent = (data) => {
    var comp = _.get(data.choiceData, 'computationPicked', '')
    return (comp === 'TwoDLarge' || comp === 'TwoDSmall')
}

const isCompIndLarge = (data) => {
    var comp = _.get(data.choiceData, 'computationPicked', '')
    return (comp === 'TwoILarge')
}

const isCompIndSmallEq = (data) => {
    var comp = _.get(data.choiceData, 'computationPicked', '')
    return (comp === 'TwoISmallEqual')
}

const graph = (graphData) => {
    var plotLines = _.get(graphData.zones, 'plotLineVals', [])
    var zoneColor = ['white', undefined, 'white']
    if (_.size(plotLines) === 4) {
        zoneColor = [undefined, 'white', undefined] // "Not Equal" fill
    }
    return {
        chartOptions: {
            chart: {
                type: 'area',
                spacing: [null, 20, 35, 20],
                spacingTop: -40,
                height: 250,
                borderWidth: 0,
            },
            xAxis: {
                endOnTick: true,
                startOnTick: true,
                tickLength: 0,
                tickInterval: 1,
                max: _.get(graphData, 'max', 3),
                min: _.get(graphData, 'min', -3),
                labels: {
                    enabled: false,
                },
                plotLines: _.get(graphData.zones, 'plotLineVals', [])
            },
            yAxis: {
                visable: false,
                lineWidth: 0,
                tickLength: 0,
                labels: {
                    enabled: false
                },
            },
            tooltip: {
                enabled: true,
            },
            legend: {
                enabled: false,
            },
            series: [
                { data: _.get(graphData, 'series', normalData.loadGraphSeries(0, 1)) },
            ],
            plotOptions: {
                area: {
                    zoneAxis: 'x',
                    zones: [{
                        fillColor: zoneColor[0],
                        value: _.get(graphData.zones, 'zLowZone', [])
                    }, {
                        fillColor: zoneColor[1],
                        value: _.get(graphData.zones, 'zHighZone', [])
                    }, {
                        fillColor: zoneColor[2],
                    }]
                }
            }

        }
    }
}

export default {
    calculateOneMean,
    calculateOneProportion,
    calculateOneVariance,
    calculateTwoMean,
    calculateTwoProportion,
    calculateTwoVariance,
    graph
}
